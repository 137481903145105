import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { guestGuard } from './guards/guest.guard';

export const APP_ROUTES: Routes = [
  {
    path: 'login',
    pathMatch: 'prefix',
    canActivate: [guestGuard],
    loadComponent: () => import('src/app/pages/login/login.page').then((mod) => mod.LoginPage),
  },
  {
    path: 'sign-up',
    canActivate: [guestGuard],
    loadComponent: () => import('src/app/pages/sign-up/sign-up.page').then((mod) => mod.SignupPage),
  },
  {
    path: 'forgot-password',
    canActivate: [guestGuard],
    loadComponent: () =>
      import('src/app/pages/login/forgot-password/forgot-password.page').then((mod) => mod.ForgotPasswordPage),
  },
  {
    path: 'reset-password',
    canActivate: [guestGuard],
    loadComponent: () =>
      import('src/app/pages/login/forgot-password/reset-password/reset-password.page').then(
        (mod) => mod.ResetPasswordPage,
      ),
  },
  {
    path: 'change-password',
    loadComponent: () =>
      import('src/app/pages/change-password/change-password.page').then((mod) => mod.ChangePasswordPage),
  },
  {
    path: 'activate',
    canActivate: [guestGuard],
    loadComponent: () => import('src/app/pages/activate/activate.page').then((mod) => mod.ActivatePage),
  },
  {
    path: 'tabs',
    canActivate: [authGuard],
    loadComponent: () => import('src/app/pages/menu/menu.page').then((mod) => mod.MenuPage),
    loadChildren: () => import('src/app/pages/menu/menu.routes').then((mod) => mod.MENU_ROUTES),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];
