import { inject } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AuthService } from '../services/auth/auth.service';

export async function guestGuard() {
  const authService = inject(AuthService);
  const navCtrl = inject(NavController);

  const account = authService.currentUser;
  if (!account) await authService.loadUser();

  if (authService.currentUser) {
    await navCtrl.navigateRoot('/tabs');
    return false;
  }
  return true;
}
