import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { isMobileOffline } from '../utils/offline.utils';
import { EMPTY } from 'rxjs';

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const platform = inject(Platform);
  if (isMobileOffline(platform) && req.url.includes('/api') && !req.url.includes('ping')) {
    return EMPTY;
  }
  return next(req);
};
