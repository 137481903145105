import { HttpClient, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  withHashLocation,
  withPreloading,
} from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app/app.component';
import { APP_ROUTES } from './app/app.routes';
import { authInterceptor } from './app/interceptors/auth.interceptor';
import { environment } from './environments/environment';
import { httpInterceptor } from './app/interceptors/http.interceptor';

if (environment.production) {
  enableProdMode();
}

function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        defaultLanguage: 'en',
      }),
      IonicStorageModule.forRoot({
        name: 's2a-testing',
        description: 'S2A Testing local db for offline capability',
        driverOrder: [Drivers.IndexedDB],
      }),
      PinchZoomModule,
    ),
    provideHttpClient(withInterceptors([httpInterceptor, authInterceptor]), withFetch()),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({
      swipeBackEnabled: false,
    }),
    provideRouter(APP_ROUTES, withPreloading(PreloadAllModules), withHashLocation()),
  ],
}).catch((err) => {
  console.error(err);
});
